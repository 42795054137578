import React, { useState, useEffect } from 'react';
import backgroundSound from '../assets/background-sound.mp3'; // Importing background sound from assets folder

const BiometricScanner = () => {
  const [scanProgress, setScanProgress] = useState(0);
  const [status, setStatus] = useState('INITIALIZING');
  const [scanPhase, setScanPhase] = useState('face'); // 'face' or 'body'
  const [audio] = useState(new Audio(backgroundSound)); // Create audio object
  const [isMuted, setIsMuted] = useState(false); // State for sound control

  // Example coordinates for scan points - replace with your own
  const scanPoints = [
    { x: 50, y: 30 },
    { x: 30, y: 50 },
    { x: 70, y: 50 },
    { x: 50, y: 70 },
  ];

  useEffect(() => {
    const handleUserInteraction = () => {
      audio.loop = true; // Set to loop
      if (!isMuted) {
        audio.play(); // Play the sound if not muted
      }
      window.removeEventListener('click', handleUserInteraction); // Remove listener after first interaction
    };

    window.addEventListener('click', handleUserInteraction); // Add event listener for user interaction

    const interval = setInterval(() => {
      setScanProgress(prev => {
        if (prev >= 100) {
          setStatus('SCAN COMPLETE');
          return 0;
        }
        setStatus('SCANNING');
        return prev + 0.5;
      });
    }, 50);
    
    return () => {
      clearInterval(interval);
      audio.pause(); // Pause the audio on cleanup
      audio.currentTime = 0; // Reset audio time
    };
  }, [audio, isMuted]);

  const toggleMute = () => {
    setIsMuted(prev => !prev);
    if (isMuted) {
      audio.play(); // Play sound if unmuted
    } else {
      audio.pause(); // Pause sound if muted
    }
  };

  return (
    <div className="min-h-screen bg-black p-4 md:p-8 font-['Share_Tech_Mono']">
      {/* Sound Control */}
      <div className="absolute top-4 right-4 opacity-40 hidden md:block"> {/* Hidden on mobile */}
        <button 
          onClick={toggleMute} 
          className="bg-[#4a6b8c] bg-opacity-50 text-white p-2 rounded" // Made background more transparent
        >
          {isMuted ? 'Unmute' : 'Mute'}
        </button>
      </div>

      {/* Header */}
      <header className="text-center mb-8">
        <h1 className="text-[#4a6b8c] text-3xl md:text-4xl font-['VT323'] tracking-[0.2em] mb-4">
          BIOMETRIC ANALYSIS
        </h1>
        <div className="text-[#7c9cbc]">
          STATUS: {status}
        </div>
      </header>

      <div className="max-w-6xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
          {/* Face Scan Panel */}
          <div className="border border-[#2c4056] bg-black/50 p-4">
            <div className="text-[#4a6b8c] mb-4">FACIAL PATTERN ANALYSIS</div>
            
            <div className="relative aspect-square">
              <svg 
                viewBox="0 0 100 100" 
                className="w-full h-full"
                style={{ filter: 'drop-shadow(0 0 2px #4a6b8c)' }}
              >
                {/* Grid Pattern */}
                <pattern id="grid" width="5" height="5" patternUnits="userSpaceOnUse">
                  <path 
                    d="M 5 0 L 0 0 0 5" 
                    fill="none" 
                    stroke="#2c4056" 
                    strokeWidth="0.1" 
                  />
                </pattern>
                <rect width="100" height="100" fill="url(#grid)" />

                {/* Face Pattern */}
                <image 
                  href="/face-pattern.svg"
                  width="80"
                  height="80"
                  x="10"
                  y="10"
                  className="opacity-80"
                />

                {/* Scan Points */}
                {scanPoints.map((point, index) => (
                  <circle
                    key={index}
                    cx={point.x}
                    cy={point.y}
                    r="0.5"
                    fill="#7c9cbc"
                    opacity={scanProgress > (index * 25) ? 0.8 : 0}
                  >
                    <animate
                      attributeName="r"
                      values="0.5;1;0.5"
                      dur="2s"
                      repeatCount="indefinite"
                    />
                  </circle>
                ))}

                {/* Scan Line */}
                <line
                  x1="0"
                  y1={scanProgress}
                  x2="100"
                  y2={scanProgress}
                  stroke="#7c9cbc"
                  strokeWidth="0.2"
                  style={{ filter: 'blur(1px)' }}
                />
              </svg>

              {/* Scan Overlay */}
              <div 
                className="absolute inset-0 bg-gradient-to-b from-transparent via-[#4a6b8c]/10 to-transparent scanline"
              />
            </div>
          </div>

          {/* Body Scan Panel */}
          <div className="border border-[#2c4056] bg-black/50 p-4">
            <div className="text-[#4a6b8c] mb-4">FULL BODY ANALYSIS</div>
            
            <div className="relative aspect-square">
              <svg 
                viewBox="0 0 100 100" 
                className="w-full h-full"
                style={{ filter: 'drop-shadow(0 0 2px #4a6b8c)' }}
              >
                <rect width="100" height="100" fill="url(#grid)" />
                
                {/* Body Silhouette */}
                <image 
                  href="/body-silhouette.svg"
                  width="50"
                  height="80"
                  x="25"
                  y="10"
                  className="opacity-80"
                />

                {/* Scan Lines */}
                {Array.from({ length: 20 }).map((_, i) => {
                  const y = (i * 5) + (scanProgress / 2);
                  return (
                    <line
                      key={`scanline-${i}`}
                      x1="0"
                      y1={y}
                      x2="100"
                      y2={y}
                      stroke="#4a6b8c"
                      strokeWidth="0.1"
                      opacity={0.3}
                    />
                  );
                })}
              </svg>
            </div>
          </div>
        </div>

        {/* Progress Bar */}
        <div className="mt-8 max-w-md mx-auto">
          <div className="h-2 bg-black border border-[#2c4056]">
            <div 
              className="h-full bg-[#4a6b8c] transition-all duration-100"
              style={{ 
                width: `${scanProgress}%`,
                boxShadow: '0 0 10px rgba(74, 107, 140, 0.3)'
              }}
            />
          </div>
          <div className="text-center mt-2 text-[#7c9cbc]">
            {Math.round(scanProgress)}% COMPLETE
          </div>
        </div>

        {/* Copyright Notice */}
        <div className="text-center mt-8 text-[#4a6b8c] text-sm opacity-80">
          Copyright © SOCRATES Government Agency 2048. All rights reserved.
        </div>
      </div>
    </div>
  );
};

export default BiometricScanner;